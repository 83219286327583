import { useEffect } from 'react'
import Router, { useRouter } from 'next/router'
import { HOME, SIGN_OUT } from 'constants/path'
import { useAuthState } from 'context/auth'

type WithAuth = (Page: React.ComponentType) => React.ComponentType

export const withAuth: WithAuth = Page => {
  const WrappedPage = () => {
    const { asPath } = useRouter()
    const { signedIn } = useAuthState()

    useEffect(() => {
      if (!signedIn) {
        const query = asPath !== HOME ? { redirect_to: asPath } : {}
        Router.replace({ pathname: SIGN_OUT, query })
      }
    }, [asPath, signedIn])

    return signedIn ? <Page /> : null
  }

  return WrappedPage
}
